import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import MarketingLayout from '../components/layout/MarketingLayout';
import UntitledStyle from '../components/layout/UntitledStyle';
import LibraryPage from '../components/library/LibraryPage';
import LibraryWithCategoriesLayout from '../components/library/LibraryWithCategoriesLayout';
import { LibraryCategoriesYamlConnection } from '../graphql-types';
import { toLibraryCategory } from '../utils/toLibraryType';

const Library: React.SFC = () => {
  const { categories }: { categories: LibraryCategoriesYamlConnection } =
    useStaticQuery(
      graphql`
        query {
          categories: allLibraryCategoriesYaml {
            edges {
              node {
                ...LibraryCategoryFragment
              }
            }
          }
        }
      `,
    );

  return (
    <MarketingLayout title="Library">
      <UntitledStyle>
        <LibraryWithCategoriesLayout>
          <LibraryPage
            categories={categories.edges.map(({ node }) =>
              toLibraryCategory(node),
            )}
          />
        </LibraryWithCategoriesLayout>
      </UntitledStyle>
    </MarketingLayout>
  );
};

export default Library;
