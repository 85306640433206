import { Link } from 'gatsby';
import _ from 'lodash';
import React from 'react';
import libraryIcon from '../../images/library/library.svg';
import { LibraryCategory } from '../../types';
import LibraryContentGrid from './LibraryContentGrid';
import styles from './LibraryPage.module.scss';
import getAllCategoryPosts from './getAllCategoryPosts';

const HOMEPAGE: [string, string[]][] = [
  [
    'company',
    [
      'culture-and-values',
      'amazon-leadership-principles',
      'starbucks-mission-culture',
      'netflix-culture',
      'linkedin-culture-values',
      'team-meeting-agendas',
      'asana-one-on-one-meeting-agenda',
      'shopify-brainstorming-session',
      'drift-senior-leadership-team-meeting',
      'g2-weekly-meeting-template',
      'user-manuals',
      'gitlab-ceo-manual',
    ],
  ],
  [
    'customer-success',
    [
      'front-onboarding-handoff',
      'gainsight-quarterly-business-review',
      'customer-onboarding',
      'vitally-success-plan',
    ],
  ],
  [
    'engineering',
    [
      'google-code-review',
      'slack-code-review',
      'code-review-guidelines',
      'squarespace-rfc-template',
      'hashicorp-rfc',
      'pagerduty-postmortem-template',
      'amazon-correction-of-errors-example',
      'postmortems',
    ],
  ],
  [
    'marketing',
    [
      'coca-cola-social-media-guidelines',
      'social-media-guidelines',
      'productboard-new-partner-discovery-meeting',
      'webflow-product-launch-plan',
    ],
  ],
  [
    'people',
    [
      'clearbit-30-60-90-day-plan-example',
      '30-60-90-day-plans',
      'greenhouse-structured-hiring-kickoff-worksheet',
      'gem-reference-call-questions',
      'carta-offer-letters',
      'helpscout-welcome-letter',
      'uber-one-on-one-skip-level',
      'justworks-offboarding-resignation',
    ],
  ],
  [
    'product',
    [
      'hashicorp-prd',
      'amazon-press-release-template',
      'dropbox-brand-foundation',
      'medium-logo-usage-guidelines',
    ],
  ],
  [
    'sales',
    [
      'salesforce-weekly-meeting-template',
      'bliinx-customer-discovery-call',
      'clubspeed-sales-intro-call',
      'salesflare-sales-call',
    ],
  ],
];

interface LibraryPageProps {
  categories: LibraryCategory[];
}

const LibraryPage: React.SFC<LibraryPageProps> = ({ categories }) => (
  <>
    <div className={styles.header}>
      <img src={libraryIcon} alt="" role="presentation" />
      <h1 className={styles.title}>Slab Library</h1>
      <h2 className={styles.subtitle}>
        Get inspired by templates and examples from leaders in the industry
      </h2>
    </div>
    {HOMEPAGE.map(([categorySlug, selectionSlugs]) => {
      const category = categories.find((c) => c.slug === categorySlug);
      if (!category) return null;

      const categorySlugMap = _.keyBy(category.collections, 'slug');
      const postSlugMap = _.keyBy(getAllCategoryPosts(category), 'slug');

      const contents = _.compact(
        selectionSlugs.map(
          (slug) => categorySlugMap[slug] || postSlugMap[slug],
        ),
      );

      return (
        <React.Fragment key={category.url}>
          <Link to={category.url} className={styles.sectionHeader}>
            <div className={styles.categoryName}>{category.name}</div>
            <div className={styles.seeAll}>
              See all templates<span className={styles.arrow}>→</span>
            </div>
          </Link>
          <LibraryContentGrid contents={contents} mobileOneRow />
        </React.Fragment>
      );
    })}
  </>
);

export default LibraryPage;
